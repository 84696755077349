import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userIdToken: null,
    userAccessToken: null
  },
  mutations: {
    setUserIdToken: (state, token) => {
      state.userIdToken = token;
    },
    setUserAccessToken: (state, token) => {
      state.userAccessToken = token;
    }
  },
  getters: {
    isSignedIn: state => {
      return state.userIdToken != null;
    },
    isSignedOut: state => {
      return state.userIdToken == null;
    },
    getUserIdJWTToken: state => {
      if (state.userIdToken) {
        return state.userIdToken.jwtToken;
      }
    },
    getUserEmail: state => {
      if (state.userIdToken) {
        return state.userIdToken.payload.email;
      }
    },
  }
})
