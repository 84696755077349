<template>
    <div>
        <!-- <VuetifyAudio ref="audioPlayer" file="https://app.shobot.io/static/terry.mp4"></VuetifyAudio> -->
        <audio @loadeddata="onDataLoaded" @timeupdate="timeUpdated" ref="audioPlayer" controls>
            <source src="https://app.shobot.io/static/terry.mp4" type="video/mp4">
        </audio>
        <v-btn @click="buttonClick">click</v-btn>
        <!-- <br> -->
        <div v-for="(utterance,i) of utterances" :key = i
                        contenteditable="true"
                        @blur="onBlur($event, i)"
                        @keyup="keyup($event, i)" 
                        @keydown="keydown($event, i)"
                        :ref="i"
                        class="utterance"
        >
            <span v-for="(word, j) of utterance.words" :key=j
                :style="[isWordActive(word) ? {'font-weight': 'bold'} : null]"
                :id="i + ',' + j"
            >
            {{word.text}} </span>
        </div>
        <!-- <br> -->

        <!-- <p >{{utterance.text}}</p> -->
    </div>   
    
</template>


<script>
// import {Auth} from 'aws-amplify';
import axios from 'axios';
// import VuetifyAudio from 'vuetify-audio';
export default {
    name: 'EditorDeprecated',
    components: {
        // VuetifyAudio,
    },
    data() {
        return {
            utterances: [],
            currentTime: 0,
            processing: false
        }
    },
    async created() {
        let headers = {'authorization': '11652b2f912a41d2a633b94e75d6ac3b'};
        let res = await axios.get('https://api.assemblyai.com/v2/transcript/bfqbcwr14-3ad7-4942-b2f9-61d469e28b10', {headers});
        this.utterances = res.data.utterances;
    },
    mounted() {
        this.value = 0;
    },
    methods: {
        buttonClick() {
            console.log(this.$refs.audioPlayer.playbackRate = 1.5);
            // this.$refs.audioPlayer.pause();
        },
        onDataLoaded() {
        },
        timeUpdated() {
            //player time is in seconds, transcript time is in milliseconds
            this.currentTime = this.$refs.audioPlayer.currentTime * 1000;
            // console.log(this.currentTime);
        },
        isWordActive(word) {
            return word.start < this.currentTime && this.currentTime < word.end;
        },
        keyup() {
            // console.log(window.getSelection().focusOffset)
            // console.log(e)
            // if (e.key === "Enter") {
            //     let updatedDiv = this.$refs[utteranceIndex][0];
            //     console.log(updatedDiv);
            // }
            
            // console.log("utteranceIndex", utteranceIndex);
            // console.log("wordIndex", wordIndex);
            // console.log(this.$refs[utteranceIndex + "," + wordIndex][0]["innerText"])
            // this.utterances[utteranceIndex].words[wordIndex].text = this.$refs[utteranceIndex + "," + wordIndex][0]["innerText"];
        },
        keydown(e, utteranceIndex) {
            if (e.key === "Enter") {
                e.preventDefault();
                this.processing = true;
                //first need to commit any changes that have already been made
                let updatedDiv = this.$refs[utteranceIndex][0];
                let words = new Array(this.utterances[utteranceIndex].words.length).fill();
                for (let i = 0; i < updatedDiv.childNodes.length; i++) {
                    let currSpan = updatedDiv.children[i];
                    let wordIndex = parseInt(currSpan.id.split(",")[1]);
                    words[wordIndex] = currSpan.innerText;
                }
                for (let i = 0; i < words.length; i++) {
                    this.utterances[utteranceIndex].words[i].text = words[i];
                }

                //next, find the span that is being broken up
                let brokenSpan = window.getSelection().anchorNode.parentElement;
                let wordIndex = parseInt(brokenSpan.id.split(",")[1]);
                let charIndex = window.getSelection().anchorOffset - 1; //everything to the right of char index is moved to the next div
                
                let word = this.utterances[utteranceIndex].words[wordIndex];
                let movingChars = word.text.slice(charIndex);
                let remainingChars = word.text.slice(0, charIndex);
                let movingWords = this.utterances[utteranceIndex].words.slice(wordIndex + 1);
                let remainingWords = this.utterances[utteranceIndex].words.slice(0, wordIndex + 1);
                this.utterances[utteranceIndex].words = remainingWords;
                this.utterances[utteranceIndex].words[wordIndex].text = remainingChars;

                let newUtterance = {speaker: this.utterances[utteranceIndex].speaker};
                let newWord = {
                    start: word.start,
                    end: word.end,
                    text: movingChars
                }
                newUtterance.words = [newWord, ...movingWords];
                this.utterances.splice(utteranceIndex + 1, 0, newUtterance);

                //focus on the newly created div
                this.$refs[utteranceIndex + 1][0].focus();
                
                this.processing = false;

                // console.log(movingChars);
                // console.log(movingWords.map(word => word.text));
                // console.log(wordIndex);

                return false;
            } else if (e.key === "Backspace") {
                if (utteranceIndex === 0) {
                    //don't need to do anything if this is the first utterance
                    return;
                }

                //we are at the start of the div if current node's id is the same as first span's id
                let currSpan = window.getSelection().anchorNode.parentElement;
                let currDiv = this.$refs[utteranceIndex][0];
                if (!(currSpan.id === currDiv.childNodes[0].id && window.getSelection().anchorOffset <= 1)) {
                    //if we aren't at the first word, and we aren't at the beginning of the first word, just do a regular backspace
                    return;
                }
                e.preventDefault();
                this.processing = true;

                //first need to commit any changes that have already been made to this utterance
                let words = new Array(this.utterances[utteranceIndex].words.length).fill();
                for (let i = 0; i < currDiv.childNodes.length; i++) {
                    let span = currDiv.children[i];
                    let wordIndex = parseInt(span.id.split(",")[1]);
                    words[wordIndex] = span.innerText;
                }
                for (let i = 0; i < words.length; i++) {
                    this.utterances[utteranceIndex].words[i].text = words[i];
                }

                //need to keep track of previous utterance length so we know where to focus later 
                let prevDiv = this.$refs[utteranceIndex - 1][0];
                let originalOldUtteranceLength = prevDiv.childNodes.length;

                //append the words in this utterance to the previous utterance
                this.utterances[utteranceIndex - 1].words.push(...this.utterances[utteranceIndex].words)

                //delete the old utterance
                this.utterances.splice(utteranceIndex, 1);

                setTimeout(() => {
                    //logic for focusing on the merged utterance
                    let range = document.createRange();
                    var sel = window.getSelection();
                    range.setStart(prevDiv.childNodes[originalOldUtteranceLength], 0)
                    range.collapse(true)
                    sel.removeAllRanges();
                    sel.addRange(range);
                    this.processing = false;
                }, 0)
            } else if (e.key === "Delete") {
                //if we're at the last utterance, don't need to do anything here
                if (utteranceIndex === this.utterances.length - 1) {
                    return;
                }

                //we are at the end of the div if the node's id is the same as the last span's id and cursor is at end of span
                let currSpan = window.getSelection().anchorNode.parentElement;
                let currDiv = this.$refs[utteranceIndex][0];
                if (!(currSpan.id === currDiv.childNodes[currDiv.childNodes.length - 1].id && window.getSelection().anchorOffset > currSpan.innerText.length)) {
                    return;
                }
                
                e.preventDefault();
                this.processing = true;

                //first need to commit any changes that have already been made to this utterance
                let words = new Array(this.utterances[utteranceIndex].words.length).fill();
                for (let i = 0; i < currDiv.childNodes.length; i++) {
                    let span = currDiv.children[i];
                    let wordIndex = parseInt(span.id.split(",")[1]);
                    words[wordIndex] = span.innerText;
                }
                for (let i = 0; i < words.length; i++) {
                    this.utterances[utteranceIndex].words[i].text = words[i];
                }

                //need to keep track of this utterance's length so we know where to focus later
                let originalOldUtteranceLength = currDiv.childNodes.length;

                //apend the words in the next utterance to this utterance
                this.utterances[utteranceIndex].words.push(...this.utterances[utteranceIndex + 1].words)

                //delete the next utterance
                this.utterances.splice(utteranceIndex + 1, 1)

                setTimeout(() => {
                    //logic for focusing on the merged utterance
                    let range = document.createRange();
                    var sel = window.getSelection();
                    range.setStart(currDiv.childNodes[originalOldUtteranceLength], 0)
                    range.collapse(true)
                    sel.removeAllRanges();
                    sel.addRange(range);
                    this.processing = false;
                }, 0)
                this.processing = false;
            }
        },
        onBlur(e, utteranceIndex) {
            if (this.processing) {
                return;
            }
            //update utterances with new values
            let updatedDiv = this.$refs[utteranceIndex][0];

            let words = new Array(this.utterances[utteranceIndex].words.length).fill();

            // console.log(this.$refs[utteranceIndex][0].childNodes);
            for (let i = 0; i < updatedDiv.childNodes.length; i++) {
                let currSpan = updatedDiv.children[i];
                let wordIndex = parseInt(currSpan.id.split(",")[1]);
                words[wordIndex] = currSpan.innerText;
            }
            // console.log(words);
            for (let i = 0; i < words.length; i++) {
                if (this.utterances[utteranceIndex].words[i]) {
                    this.utterances[utteranceIndex].words[i].text = words[i];
                }
            }
            // console.log(this.utterances[utteranceIndex].words.map(word => word.text));
            //TODO: at some point, delete the deleted words from utterances list?
            // console.log(this.ut terances[utteranceIndex].words);
            // this.utterance[utteranceIndex]
            // this.utterances[utteranceIndex].words[wordIndex].text = this.$refs[utteranceIndex + "," + wordIndex][0]["innerText"];
        },
        onClick() {
            // console.log("span clicked")
        },
        handleInput() {
            // console.log(e);
        }
    }
}
</script>

<style lang="css">

#transcript {
  overflow: scroll
}
/* 
.utterance {
    display: inline-block;
    margin-top: 10px;
} */
</style>