<template>
  <div class="home">
    

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col align="center" rows="12" md="6"><h3>My Transcriptions</h3></v-col>
        <v-col align="center" rows="12" md="6">
            <router-link to="/newTranscription">
              <v-btn
                color="primary"
              >Upload new file</v-btn>
            </router-link>
        </v-col>
      </v-row>
      
      <br>

      <v-simple-table v-if="transcriptions && transcriptions.length > 0">
        <thead>
        <tr>
          <th>Name</th>
            <th>Status</th>
          <th>Date Submitted</th>
          <th>File Name</th>
          <th>Duration</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(t, i) in transcriptions" :key="i">
          <td v-if="t.status === 'COMPLETED'">
            <router-link :to="'/editor?transcriptionId=' + t._id">{{t.name}}</router-link>
          </td>
          <td v-else>{{t.name}}</td>
          <td>
            {{t.status | statusFormat}}
            <v-icon v-if="t.status === 'TRANSCRIBING'">fas fa-circle-notch fa-spin</v-icon>
          </td>

          <td>{{t.createdAt | dateFormat}}</td>
          <td>{{t.originalFileName}}</td>
          <td>{{t.duration | durationFormat}}</td>
        </tr>
        </tbody>
      </v-simple-table>
      <p v-else style="text-align:center; margin-top:20%;">
        You haven't created any transcriptions yet. Click <router-link to="/newTranscription">here</router-link> to submit your first transcription request.
      </p>
    </v-container>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      transcriptions: []
    }
  },
  computed: {
    ...mapGetters({
      signedIn: 'isSignedIn',
      userIdJWTToken: 'getUserIdJWTToken',
    })
  },
  async created() {
    await this.getTranscriptions()
  },
  filters: {
    dateFormat: function(value) {
      let date = new Date(value);
      return date.toLocaleString();
    },
    durationFormat: function(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);

      let string = "";
      if (hours > 0)  {
          string += `${hours} hour`
      }
      if (hours > 1) {
          string += 's'
      }

      string += ` ${minutes} minutes`;
      return string;
    },
    statusFormat: function(status) {
      if (status === "COMPLETED") {
        return "Transcribed"
      } else if (status === "FAILED") {
        return "Failed - please contact Support"
      } else {
        return "Transcribing"
      }
    }
  },
  methods: {
    async getTranscriptions() {
      let headers = {"Authorization": this.userIdJWTToken};
      let res = await axios.get(process.env.VUE_APP_API_HOST+"/transcription", {headers});
      this.transcriptions = res.data;
    }
  }
}
</script>

<style lang="css">
.videoCard:hover {
  cursor: pointer;
  border: 1px groove red;
  /* border-style: groove; */
}
</style>
