import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import Zoom from '../components/Zoom.vue';
import Stream from '../components/Stream.vue';
import NewTranscription from '../components/NewTranscription.vue';
import EditorDeprecated from '../components/Editor-Deprecated.vue';
import Editor from '../components/Editor.vue';

import store from '../store/index';
// import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/zoom',
    name: 'Zoom',
    component: Zoom,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stream',
    name: 'Stream',
    component: Stream,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newTranscription',
    name: 'NewTranscription',
    component: NewTranscription,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editor-deprecated',
    name: 'Editor-Deprecated',
    component: EditorDeprecated,
    meta: {
      requiresAuth: true
    }
  },{
    path: '/editor',
    name: 'Editor',
    component: Editor,
    meta: {
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth) {
    if (store.getters.isSignedIn) {
      next();
    } else {
      next('login');
    }
  } else {
    next();
  }
  // if (requiresAuth) {
  //   try {
  //     const session = await Auth.currentSession();
  //     store.commit('setUserIdToken', session.idToken);
  //     store.commit('setUserAccessToken', session.accessToken);
  //     next();
  //   } catch (err) {
  //     store.commit('setUserIdToken', null);
  //     store.commit('setUserAccessToken', null);
  //     next('login');
  //   }
  // } else {
  //   next();
  // }
})



export default router