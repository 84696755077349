<template>
  <v-app id="app">
    <v-app-bar fixed color="white" app v-if="signedIn && !$route.name.includes('Editor')">
      <!-- <v-toolbar-title @click="navigateToHomePage" id="homeIcon">shobot</v-toolbar-title> -->
      <router-link to="/">
            <img src="/static/logo.png" height="40">
      </router-link>
      <v-spacer></v-spacer>
      
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn> -->
            <v-app-bar-nav-icon
              v-bind="attrs"
              v-on="on"  
            >

            </v-app-bar-nav-icon>
          <!-- </v-btn> -->
        </template>
        <v-list>
          <v-list-item id="emailListItem" inactive>
            <v-list-item-title><em>{{userEmail}}</em></v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="item.action"
            link
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
      
    <v-main id="main">
      <!-- <v-container> -->
        <router-view></router-view>
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import {Auth} from 'aws-amplify';
import axios from 'axios';
// import About from './views/About';

export default {
  name: 'App',
  data() {
    return {
      menuItems: [
        {title: 'Manage Billing', action: this.goToBilling},
        // {title: 'Get Help', action: this.getHelp},
        {title: 'Sign Out', action: this.signOut}
      ]
    }
  },
  components: {
    // About
  },
  computed: {
    ...mapGetters({
      signedIn: 'isSignedIn',
      signedOut: 'isSignedOut',
      userEmail: 'getUserEmail',
      userIdJWTToken: 'getUserIdJWTToken',
    })
  },
  async created() {
    await this.checkSignIn();
  },
  methods: {
    ...mapMutations([
      'setUserIdToken',
      'setUserAccessToken'
    ]),
    async checkSignIn() {
      

      // try {
      //     let session = await Auth.currentSession();
      //     console.log("setting userIdToken in app created");
      //     this.setUserIdToken(session.idToken);
      //     this.setUserAccessToken(session.accessToken);
      // } catch (err) {
      //     //no one is signed in, or refresh token is expired
      //     this.setUserIdToken(null);
      //     this.setUserAccessToken(null);
      // }
    },
    navigateToHomePage() {
      if (this.$router.currentRoute.name != "Home") {
        this.$router.push('/')
      }
    },
    goToManageVideos() {
      if (window.location.pathname.toLowerCase() != "zoom") {
        this.$router.push("/Zoom");
      }
    },
    signOut() {
      Auth.signOut();
      this.$router.push("Login");
      this.setUserIdToken(null);
      this.setUserAccessToken(null);
    },
    async getHelp() {
      window.open("https://shobot.io/contact-us")
    },
    async goToBilling() {
      //backend redirects to stripe billing page
      let headers = {"Authorization": this.userIdJWTToken};
      let res = await axios.post(process.env.VUE_APP_API_HOST+"/stripe/createPortalSession/", null, {headers});
      let redirectUrl = res.data.redirectUrl;
      window.location.href = redirectUrl;
    }
  }
};
</script>

<style lang="css">
#app {
  font-family: 'Poppins', sans-serif;
}

#main {
  background-color: #f0f0f0;
  font-size: 1rem;
  font-weight: 300;
  color: #212529;
}

#homeIcon {
  cursor: pointer; 
}

img.logo {
  margin: 10px;
  /* height: 50px; */
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}

#emailListItem:hover {
  cursor: auto;
}

</style>