import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
// import { VideoStream } from 'stream-vue'
import LoadScript from 'vue-plugin-load-script';
import Amplify, {Auth} from 'aws-amplify';
Vue.use(LoadScript);


import store from './store'
import Vuex from 'vuex';
// Vue.use( VideoStream );
Vue.use(Vuex);
Vue.config.productionTip = false;

Amplify.configure({
  region: process.env.VUE_APP_COGNITO_USER_POOL_REGION,
  userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID
});

Auth.currentSession()
  .then(session => {
    store.commit('setUserIdToken', session.idToken);
    store.commit('setUserAccessToken', session.accessToken);
  })
  .catch(() => {
    store.commit('setUserIdToken', null);
    store.commit('setUserAccessToken', null);
  })
  .finally(() => {
    new Vue({
      router,
      vuetify,
      store,
      components: {App},
      render: h => h(App)
    }).$mount('#app')  
  })