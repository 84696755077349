<template>
        <v-container>
            <h2>Submit new audio or video for transcription</h2>
            <br>
            <v-row>
                <v-col>
            <v-stepper id="stepper"  vertical v-model="step" >
                <!-- <v-stepper-header>
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                        Upload Audio or Video File
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                        Submit Payment
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        step="3"
                        :complete="step==3"
                    >
                        Confirmation
                    </v-stepper-step>
                </v-stepper-header> -->
                <!-- <v-stepper-header> -->
                    <!-- <v-card> -->
                        <!-- <v-card-title>Submit new audio or video for transcription</v-card-title> -->
                    <!-- </v-card> -->
                <!-- </v-stepper-header> -->

                <v-stepper-items>
                                        <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                        Upload Audio or Video File
                    </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card color="transparent">
                        <!-- <v-card-title> Submit a new transcription request</v-card-title> -->
                        <v-card-text>
                            
                            <v-form>
                                <v-row>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    outlined
                                    label="Transcription Title"
                                    v-model="transcriptionTitle"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-file-input
                                    ref="fileInput"
                                    outlined
                                    @change="fileUpload"
                                    accept="audio/mpeg,audio/mp4,video/mp4,video/quicktime,audio/wav,audio/flac,audio/amr,audio/ogg,video/ogg,audio/webm,video/webm"
                                    label="Audio/Video Upload"
                                    persistent-hint
                                    hint="Accepted file formats: MP3, MOV, MP4, WAV, FLAC, AMR, OGG, and WebM">
                                </v-file-input>
                                <p v-if="durationString">
                                    Upload length: {{durationString}}
                                </p>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-select 
                                    v-model="numSpeakers"
                                    :items="speakerNumbers"
                                    placeholder="Number of speakers"
                                    persistent-hint
                                    hint="Approximate number of speakers in the audio file. (max 10)"></v-select>
                                </v-col>
                                </v-row>

                                <!-- <v-textarea
                                    outlined
                                    label="Notes/Comments"
                                    v-model="details">
                                </v-textarea> -->
                            </v-form>
                            
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="goToStep2"
                                :disabled="!transcriptionTitle || !file || !numSpeakers">Continue</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                        Submit Payment
                    </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card>
                        <v-card-text>
                            <p>
                                <b>Transcription Title:</b> {{transcriptionTitle}}
                                <br>
                                <b>File Details:</b> <span v-if="file">{{file.name}}, {{durationString}}</span>
                                <br>
                                <b>Total Charge: </b> 
                                <span v-if="paymentDetails">
                                    <b>${{paymentDetails.cost}}</b> - {{durationString}} @ $0.20/min 
                                    <span v-if="paymentDetails.usingFreeMinutes != '0.00'">(using {{paymentDetails.usingFreeMinutes}} free minutes)</span>
                                </span>
                                <br>
                            </p>
                            <br><br>
                            <v-form v-if="paymentDetails && paymentDetails.cost > 0">
                                <v-radio-group v-model="selectedPaymentMethod" mandatory>
                                    <v-radio v-for="(method, i) in existingPaymentMethods"
                                        :key="i"
                                        :label="`${method.brand} ending in ${method.last4}`"
                                        :value="method.id"
                                    ></v-radio>
                                    <v-radio label="Enter a new Credit Card" value="newCreditCard"></v-radio>
                                </v-radio-group>
                            </v-form>
                            <v-row>
                                <v-col cols="12" md="6">
                                <div id="stripe-card-container" :style="[selectedPaymentMethod == 'newCreditCard' ? null : {'display': 'none'}]"></div>
                                <p style="color:red" :style="[selectedPaymentMethod == 'newCreditCard' ? null : {'display': 'none'}]">{{ cardErrorMessage }}</p>
                                </v-col>
                            </v-row>
                            <!-- <p v-if="paymentDetails && paymentDetails.freeMinutes > 0 && !paymentDetails.hasDefaultPaymentMethod && duration / 60 > paymentDetails.freeMinutes" style="color:green">
                                You have {{paymentDetails.freeMinutes}} free minutes remaining. If you'd like to transcribe your entire file, please add a payment method in the "Manage Billing" page. If not, we will only transcribe the first {{paymentDetails.freeMinutes}} minutes of your file.
                            </p> -->
                            <!-- <p v-if="paymentDetails && !paymentDetails.hasDefaultPaymentMethod && paymentDetails.freeMinutes <= 0" style="color:red">
                                You don't have a default payment method on your account. To continue, please add one now.
                            </p> -->
                            <p v-if="errorMessage" style="color:red">{{errorMessage}}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="step=1">Go Back</v-btn>
                            <v-btn
                                color="primary"
                                :disabled="step2ButtonDisabled()"
                                @click="submitTranscription"
                            >
                                    Process Payment 
                                    <i v-if="paymentProcessing" class="fas fa-circle-notch fa-spin"></i>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                                    <v-stepper-step
                        step="3"
                        :complete="step==3"
                    >
                        Confirmation
                    </v-stepper-step>

                <v-stepper-content step="3">
                    <v-card>
                        <v-card-text>
                            <p>Thank you - your payment has been received. Our AI is transcribing your audio file.</p>
                            <p>
                                <b>Transcription Title:</b> {{transcriptionTitle}}
                                <br>
                                <b>File Details:</b> <span v-if="file">{{file.name}}, {{durationString}}</span>
                                <br>
                                <b>Total Charge: </b> 
                                <span v-if="paymentDetails">
                                    <b>${{paymentDetails.cost}}</b> - {{durationString}} @ $0.20/min 
                                    <span v-if="paymentDetails.usingFreeMinutes != '0.00'">(using {{paymentDetails.usingFreeMinutes}} free minutes)</span>
                                </span>
                                <br>
                            </p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text 
                            :disabled="fileUploadState !== 'SUCCESS'"
                            @click="goHome">Go Home</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
            <v-banner single-line v-if="fileUploadState=='PROCESSING'">
                <v-icon slot="icon" size="36">fas fa-circle-notch fa-spin</v-icon>
                Your file is uploading. Please do not navigate away from this page until your file has completed uploading.
            </v-banner>
            <v-banner v-if="fileUploadState=='SUCCESS'">
                <v-icon slot="icon" size="36" color="green">fas fa-check</v-icon>
                Your file has finished uploading. You can navigate away from this page.
            </v-banner>
            <v-banner v-if="fileUploadState=='FAIL'">
                <v-icon slot="icon" size="36" color="red">fas fa-exclamation-triangle</v-icon>
                Your file failed to upload. Please contact support.
            </v-banner></v-col>
            </v-row>
        </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'NewTranscription',
    data() {
        return {
            step: 1,
            duration: null,
            transcriptionTitle: null,
            file: null,
            // details: null,
            step2Loading: false,
            paymentDetails: null,
            existingPaymentMethods: null,
            successMessage: null,
            errorMessage: null,
            paymentProcessing: false,
            fileUploadState: null,
            numSpeakers: null,
            speakerNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            stripeClientSecret: null,//only used for new card input
            newCardPaymentIntentId: null,
            stripe: null,
            card: null,
            selectedPaymentMethod: null,
            cardErrorMessage: "",
            cardInputComplete: false
        }
    },
    created() {
        this.$loadScript("https://js.stripe.com/v3/")
            .then(() => {
                this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
            })
        //show a dialog before the user navigates away
        window.onbeforeunload = function() {
            return true;
        }
    },
    computed:{
        ...mapGetters({
        signedIn: 'isSignedIn',
        userIdJWTToken: 'getUserIdJWTToken',
        }),
        durationString() {
            if (this.duration) {
                let totalSeconds = this.duration;
                let hours = Math.floor(totalSeconds / 3600);
                totalSeconds %= 3600;
                let minutes = Math.floor(totalSeconds / 60);

                let string = "";
                if (hours > 0)  {
                    string += `${hours} hour`
                }
                if (hours > 1) {
                    string += 's'
                }

                string += ` ${minutes} minutes`;
                return string;
                // return `${hours}:${minutes}`;
            } else {
                return null;
            }
        },
    },
    methods: {
        fileUpload(e) {
            let video = document.createElement('video');
            console.log(e)
            video.preload = 'metadata';

            let that = this;
            video.onloadedmetadata = function() {
                window.URL.revokeObjectURL(video.src);
                that.duration = video.duration;
            }
            video.src = URL.createObjectURL(e);
            this.file = e;
        },
        async goToStep2() {
            this.errorMessage = null;
            this.step = 2;
            this.paymentDetails = null;

            this.step2Loading = true;
            let headers = {"Authorization": this.userIdJWTToken};
            let res = await axios.get(process.env.VUE_APP_API_HOST+`/users/paymentIntent?duration=${this.duration}`, {headers});
            this.step2Loading = false;
            this.paymentDetails = res.data;
            this.paymentDetails.cost = res.data.cost.toFixed(2);
            this.paymentDetails.usingFreeMinutes = res.data.usingFreeMinutes.toFixed(2);
            this.paymentDetails.freeMinutes = res.data.freeMinutes.toFixed(2);

            this.stripeClientSecret = res.data.stripeClientSecret;

            if (this.paymentDetails.cost > 0) {
                this.existingPaymentMethods = res.data.existingPaymentMethods.sort(method => method.isDefault ? -1 : 1);
                if (this.existingPaymentMethods.length > 0) {
                    this.selectedPaymentMethod = this.existingPaymentMethods[0].id;
                }

                var elements = this.stripe.elements();
                var style = {
                base: {
                    color: "#32325d",
                    fontFamily: 'Roboto, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                    color: "#32325d"
                    }
                },
                invalid: {
                    fontFamily: 'Roboto, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
                };
                var card = elements.create("card", {style});
                this.card = card;
                card.mount("#stripe-card-container")
                card.on("change", (e) => {
                    this.cardInputComplete = e.complete;
                    this.cardErrorMessage = e.error ? e.error.message : null
                })
            }
        },
        async submitTranscription() {
            this.paymentProcessing = true;
            //if using a new credit card, then we first need to process the new credit card
            if (this.selectedPaymentMethod === 'newCreditCard' && this.cardInputComplete) {
                try {
                    await this.processNewCard();
                } catch (err) {
                    this.cardErrorMessage = err;
                    this.paymentProcessing = false;
                    return;
                }
            }

            //create transcription in backend and process payment
            let headers = {"Authorization": this.userIdJWTToken};
            let body = {
                fileType: this.file.type,
                duration: this.duration,
                originalFileName: this.file.name,
                name: this.transcriptionTitle,
                numberOfSpeakers: this.numSpeakers,
                paymentFormId: this.selectedPaymentMethod,
                newCardPaymentIntentId: this.newCardPaymentIntentId ? this.newCardPaymentIntentId : null
            }

            let res;
            try {
                res = await axios.post(process.env.VUE_APP_API_HOST+"/transcription", body, {headers});
            } catch (err) {
                this.errorMessage = "Your payment failed to process. Please check your payment details and try again."
                this.paymentProcessing = false;
                return;
            }
            console.log(res.data.signedUrl);
            
            // let uploadFile = this.file;
            // uploadFile.name = res.fileName;
            
            //next - upload to that url
            let formData = new FormData();
            formData.append("file", this.file);

            this.fileUploadState = 'PROCESSING'
            console.log("file type:", this.file.type)
            var options = {
                headers: {
                'Content-Type': this.file.type
                }
            };

            //upload file to s3
            try {
                await axios.put(res.data.signedUrl, this.file, options)
            } catch (err) {
                console.log("file upload failed", err)
                this.fileUploadState = 'FAIL';
                this.step = 3;
                return;
            }
            
            //tell the backend that file upload is complete so it can begin transcription
            try {
                let headers = {"Authorization": this.userIdJWTToken};
                await axios.post(`${process.env.VUE_APP_API_HOST}/transcription/${res.data.transcriptionId}/beginTranscription`, {}, {headers});
                this.fileUploadState = 'SUCCESS';
                this.step = 3;
            } catch (err) {
                console.log("beginTranscription request failed", err);
                this.fileUploadState = 'FAIL';
                this.step = 3;
                return;
            }

            this.step = 3;
        },
        step2ButtonDisabled() {
            if (this.step2Loading || this.paymentProcessing) {
                return true;
            }
            if (!this.selectedPaymentMethod) {
                return false;
            }
            if (this.selectedPaymentMethod) {
                if (this.selectedPaymentMethod != 'newCreditCard') {
                    return false; //using an existing payment method. default to false.
                } else {
                    //using a new payment method, so only let them move forward if the card input is complete
                    if (this.cardInputComplete) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }

        },
        async processNewCard() {
            let result = await this.stripe.confirmCardPayment(this.stripeClientSecret, {
                payment_method: {
                    card: this.card
                }
            });
            if (result.error) {
                throw result.error.message;
            }
            this.newCardPaymentIntentId = result.paymentIntent.id;
        },
        goHome() {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="css">

th {
  text-align: right;
  padding-right: 30px;
  min-width: 200px;
}

#stepper {
     /* background-color: transparent; */
     color: black;
}

.step-card {
    /* background-color: transparent; */
}

#stripe-card-container {
    /* height: 55px;
    /* padding: 15px; */
    /* border: 1px solid #e7e7e7; */
    /* border-radius: 5px; */
  border-radius: 6px;
  margin-bottom: 6px;
  padding-top: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 18px;
  width: 100%;
  min-width: 250px;
  background: white;
  margin-left: -10px;
}
/* td {
    text-align: right;
} */
</style>