<template fill-height>
<div>
    <v-btn @click="redirectToZoom" v-if="displayZoomIntegrationButton">
        click to install zoom
    </v-btn>
    <v-simple-table v-if="videos" dense>
        <thead>
            <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Host</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="video in videos" :key="video.id">
                <td>{{video.topic}}</td>
                <td>{{video.date}}</td>
                <td>{{video.hostEmail}}</td>
                <td>{{video.state}}</td>
                <td v-if="video.state === 'Not imported'">
                    <i v-if="video.submitLoading" class="fas fa-circle-notch fa-spin"></i>
                    <a v-else @click="importVideo(video)">Import</a>
                </td>
                <td v-else></td>
            </tr>
        </tbody>
    </v-simple-table>
    <v-dialog v-model="confirmImportDialog.open" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                Confirm import
                <span>
                    Are you sure you want to import this video?
                </span>
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="confirmImportDialog={open: false, video: null}">Cancel</v-btn>
                <v-btn text @click="importVideo()">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'Zoom',
    data() {
        return {
            displayZoomIntegrationButton: false,
            videos: null,
            confirmImportDialog: {
                open: false,
                video: null
            }
        }
    },
    computed: {
        ...mapGetters({
        signedIn: 'isSignedIn',
        userIdJWTToken: 'getUserIdJWTToken',
        })
    },
    async created() {
        console.log(process.env.VUE_APP_REDIRECT_URL);
        //if there is a "code" query param, then this is the middle of a redirect flow. submit the code to backend.
        let code = this.$route.query.code;
        if (code) {
            this.submitZoomRegistration(code);
        }

        let zoomIntegrated = await this.checkZoomIntegration();
        //if they don't, then show option to add zoom
        if (!zoomIntegrated) {
            this.displayZoomIntegrationButton = true;
            return;
        }

        //if they do, then get their latest videos
        await this.getVideos();
    },
    methods: {
        async redirectToZoom() {
            const url = process.env.VUE_APP_REDIRECT_URL;
            window.location.href = url;
        },
        async checkZoomIntegration() {
            let headers = {"Authorization": this.userIdJWTToken};
            let res = await axios.get(process.env.VUE_APP_API_HOST+"/users/zoomIntegration", {headers});
            return res.data.zoomIntegrated;
        },
        async submitZoomRegistration(code) {
            let headers = {"Authorization": this.userIdJWTToken};
            axios.post(process.env.VUE_APP_API_HOST+"/users/zoomIntegration", {code}, {headers})
                .then(() => {
                    this.$router.push("/");
                })
                .catch(err => {
                    console.log(err);
                })
        },
        async getVideos() {
            let headers = {"Authorization": this.userIdJWTToken};
            let res = await axios.get(process.env.VUE_APP_API_HOST+"/zoom/videos", {headers});
            this.videos = res.data.zoomVideos;
            console.log(res);
        },
        openConfirmImportDialog(video) {
            this.confirmImportDialog.video = video;
            this.confirmImportDialog.open = true;
        },
        async importVideo(video) {
            console.log("import:", video);
            this.$set(video, "submitLoading", true);
            let headers = {"Authorization": this.userIdJWTToken};
            axios.post(process.env.VUE_APP_API_HOST+"/zoom/importVideo", video, {headers})
                .then(async () => {
                    this.$set(video, "submitLoading", false);
                    this.$set(video, "state", "Processing");
// console.log("got to submit success")
                    //reload the page so we pull in their zoom info
                    // location.reload();
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }
}
</script>