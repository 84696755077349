<template fill-height>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
        <v-card    
            class="mx-auto"
            width="344"
            outlined align="center" justify="center" elevation="3"
        >
        <img class="logo" src="/static/logo.png" height="40">
        <template v-if="loginState==='signup'">
            <v-card-title>Sign Up</v-card-title>
            <v-card-subtitle class="text-left">
                Create a free account to get started converting audio to text.        
            </v-card-subtitle>
            <v-card-text>
            <v-form v-model="signupForm.valid" @submit.prevent>
                <v-text-field type="email" label="Email" placeholder="email@example.com" v-model.trim="signupForm.email"/>
                <v-text-field type="password" label="Password" placeholder="password" v-model.trim="signupForm.password"/>
                                <p><span style="color:red">{{ errorMessage }}</span></p>

                <br/>
                <v-btn 
                    :disabled="!isSignupFormValid() || loading"
                    @click="signUp">
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else>Sign Up</span>
                </v-btn>
                <br /><br/>
                <p>Already have an account? <router-link to="/login">Sign In</router-link></p>
            </v-form>
            </v-card-text>
        </template>

        <template v-if="loginState==='verification'">
            <v-card-title>One last step</v-card-title>
            <v-card-subtitle class="text-left">A verification code was sent to your email. Enter the code below.</v-card-subtitle>
            <v-card-text>
                <v-form v-model="verificationForm.valid" @submit.prevent>
                    <v-text-field type="text" label="code" placeholder="Verification code" v-model.trim="verificationForm.code"/>
                    <p><span style="color:red">{{ errorMessage }}</span></p>
                    <br/>
                    <v-btn 
                        :disabled="!verificationForm.code || loading"
                        @click="verifyEmail">
                        <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                        <span v-else>Verify</span>
                    </v-btn>
                </v-form>
            </v-card-text>
        </template>
        </v-card>
        </v-row>
    </v-container>

</template>

<script>
import {mapMutations} from 'vuex';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: "Register",
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
                valid: false
            },
            signupForm: {
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                valid: false
            },
            verificationForm: {
                code: '',
                email: '',
                valid: false
            },
            forgotPasswordForm: {
                email: '',
                valid: false
            },
            nameRules: [
                v => !!v || 'Name is required'
            ],
            emailRules: [
                v=>!!v || 'Email is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v=>!!v || 'Password is required'
            ],
            confirmPasswordRules: [
                v=>v == this.signupForm.password || 'Passwords must match'
            ],
            newPasswordForm: {
                password: '',
                confirmPassword: '',
                valid: false
            },
            loginState: 'signup',
            successMessage: '',
            errorMessage: '',
            user: null,
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            signedIn: 'isSignedIn',
            userIdJWTToken: 'getUserIdJWTToken',
        })
    },
    props: {
        value: Boolean
    },
    async created() {
        if (this.signedIn) {
            this.$router.push("/");
        }
    },
    methods: {
        ...mapMutations([
            'setUserIdToken',
            'setUserAccessToken'
        ]),
        async onSignInFlowCompleted() {
            Auth.currentSession()
                .then(session => {
                    this.setUserIdToken(session.idToken);
                    this.setUserAccessToken(session.accessToken);
                    this.$router.push("/newTranscription");
                });
        },
        async signUp() {
            this.loading = true;
            try {
                await Auth.signUp({
                    username: this.signupForm.email,
                    password: this.signupForm.password,
                    attributes: {
                        name: this.signupForm.name
                    }
                });

                await this.createNewUser();
                
                this.errorMessage = '';
                this.verificationForm.email = this.signupForm.email;
                this.loginState = 'verification';
            } catch (err) {
              if (err.message.includes("Password did not conform with policy")) {
                this.errorMessage = "Password must be at least 8 characters and contain a number, a special character, an uppercase letter, and a lowercase letter."
              } else {
                this.errorMessage = err.message;
              }
            }
            this.loading = false;
        },
        isSignupFormValid() {
            if (this.signupForm.email && this.signupForm.password) {
              return true;
            } else {
              return false;
            }
        },
        async createNewUser() {
            try {
                await axios.post(process.env.VUE_APP_API_HOST + "/users", {
                    username: this.signupForm.email
                });
            } catch (err) {
                //TODO: do something here?
            }
        },
        async verifyEmail() {
            this.loading = true;
            try {
                await Auth.confirmSignUp(this.verificationForm.email, this.verificationForm.code);
                await Auth.signIn(this.signupForm.email, this.signupForm.password);
                this.onSignInFlowCompleted();
                this.$router.push("/login?emailVerified=true")
            } catch (err) {
                this.errorMessage = err.message;
            }
            this.loading = false;
        },
        getNewPassword() {
            this.loginState = "newPassword";
        },
        submitNewPassword() {
            Auth.completeNewPassword(this.user, this.newPasswordForm.password)
                .then(user => {
                    this.user = user;
                    this.onSignInFlowCompleted();
                })
                .catch(() => {
                    this.errorMessage = "Your password must be at least 8 characters long and include: an uppercase letter, a lowercase letter, a number, and a symbol.";
                })
        },
    }
};
</script>

<style>
</style>