<template fill-height>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-card     
                class="mx-auto"
                width="344"
                outlined align="center"
                justify="center"
                elevation="3"
            >
                <img class="logo" src="/static/logo.png" height="40">
                <template v-if="loginState==='login'">
                    <v-card-title>Log In</v-card-title>
                    <v-card-subtitle class="text-left">Log in using your shobot credentials.</v-card-subtitle>
                    <v-card-text>
                        <p><span style="color:green">{{ successMessage }}</span></p>
                        <v-form v-model="loginForm.valid" @submit.prevent>
                            <v-text-field type="email" label="Email" placeholder="email@example.com" v-model.trim="loginForm.email"/>
                            <v-text-field type="password" label="Password" placeholder="password" v-model.trim="loginForm.password"/>
                            <p><span style="color:red">{{ errorMessage }}</span></p>
                            <br/>
                            <v-btn 
                                @click="logIn" 
                                :disabled="!loginForm.email || !loginForm.password || loading">
                                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                                    <span v-else>Log In</span>
                                </v-btn>
                            <br /><br/>
                            <p>Don't have an account? <router-link to="/register">Sign Up</router-link></p>
                        </v-form>
                    </v-card-text>
                </template>

                <template v-if="loginState==='newPassword'">
                    <v-card-title>
                        <v-card-title>Set a new password</v-card-title>
                    </v-card-title>
                    <v-card-text>
                        <p><span style="color:red">{{ errorMessage }}</span></p>
                        <v-form v-model="newPasswordForm.valid" @submit.prevent>
                            <v-text-field type="password" label="Password" placeholder="password" v-model.trim="newPasswordForm.password"></v-text-field>
                            <v-text-field type="password" label="Confirm Password" placeholder="password" v-model.trim="newPasswordForm.confirmPassword"></v-text-field>
                            <br/>
                            <v-btn :disabled="newPasswordForm.password !== newPasswordForm.confirmPassword || newPasswordForm.password.length < 8" @click="submitNewPassword">Submit</v-btn>
                            <br /><br/>
                        </v-form>
                    </v-card-text>
                </template>

                <template v-if="loginState==='verification'">
                    <v-card-title>Verify your email</v-card-title>
                    <v-card-subtitle class="text-left">A verification code was sent to your email. Enter the code below.</v-card-subtitle>
                    <v-card-text>
                        <v-form v-model="verificationForm.valid" @submit.prevent>
                            <v-text-field type="text" label="code" placeholder="Verification code" v-model.trim="verificationForm.code"/>
                            <p><span style="color:red">{{ errorMessage }}</span></p>
                            <br/>
                            <v-btn 
                                :disabled="!verificationForm.code || loading"
                                @click="verifyEmail">
                                <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                                <span v-else>Verify</span>
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </template>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import {mapMutations} from 'vuex';
import {Auth} from 'aws-amplify';
import { mapGetters } from 'vuex';

export default {
    name: "Login",
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
                valid: false
            },
            signupForm: {
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                valid: false
            },
            verificationForm: {
                code: '',
                email: '',
                valid: false
            },
            forgotPasswordForm: {
                email: '',
                valid: false
            },
            nameRules: [
                v => !!v || 'Name is required'
            ],
            emailRules: [
                v=>!!v || 'Email is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v=>!!v || 'Password is required'
            ],
            confirmPasswordRules: [
                v=>v == this.signupForm.password || 'Passwords must match'
            ],
            newPasswordForm: {
                password: '',
                confirmPassword: '',
                valid: false
            },
            loginState: 'login',
            successMessage: '',
            errorMessage: '',
            user: null,
            loading: false,
            emailVerified: false
        };
    },
    computed: {
        ...mapGetters({
            signedIn: 'isSignedIn',
            userIdJWTToken: 'getUserIdJWTToken',
        })
    },
    props: {
        value: Boolean
    },
    async created() {
        if (this.signedIn) {
            this.$router.push("/");
        }
        if (this.$route.query.emailVerified) {
            this.successMessage = "Your email has been verified. You can now log in.";
        }
    },
    methods: {
        ...mapMutations([
            'setUserIdToken',
            'setUserAccessToken'
        ]),
        async onSignInFlowCompleted() {
            Auth.currentSession()
                .then(session => {
                    this.setUserIdToken(session.idToken);
                    this.setUserAccessToken(session.accessToken);
                    this.$router.push("/");
                });
        },
        async logIn() {
            this.loading = true;
            Auth.signIn(this.loginForm.email, this.loginForm.password)
            .then(user => {
                this.user = user;
                this.successMessage = '';
                this.errorMessage = '';
                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    this.getNewPassword();
                } else {
                    this.onSignInFlowCompleted();
                }
                this.loading = false;
            })
            .catch(err => {
                this.successMessage = "";
                if (err.code === "UserNotConfirmedException") {
                    this.verificationForm.email = this.loginForm.email;
                    this.errorMessage = "";
                    this.loginState = "verification";
                } else {
                    console.log(err);
                    this.errorMessage = "Incorrect username or password.";
                }
                this.loading = false;
            })
        },
        async verifyEmail() {
            try {
                await Auth.confirmSignUp(this.verificationForm.email, this.verificationForm.code);
                this.loginState = "login";
                this.successMessage = 'Your email was verified successfully. You can now log in.';
                this.errorMessage = "";
            } catch (err) {
                this.errorMessage = err.message;
            }
        },
        getNewPassword() {
            this.loginState = "newPassword";
        },
        submitNewPassword() {
            Auth.completeNewPassword(this.user, this.newPasswordForm.password)
                .then(user => {
                    this.user = user;
                    this.onSignInFlowCompleted();
                })
                .catch(() => {
                    this.errorMessage = "Your password must be at least 8 characters long and include: an uppercase letter, a lowercase letter, a number, and a symbol.";
                })
        }
    }
};
</script>

<style>
</style>