<template>
    <div>
        <v-container id="container">
            <v-row id="row">
                <v-col cols="8">
                    <iframe
                        id="videoPlayer"
                        :src="cloudflareUrl"
                        style="border: none;"
                        height="360"
                        width="640"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowfullscreen="true"
                        ></iframe>
                        <v-btn @click="copyToClipboard">Share this moment  <i class="fas fa-link"></i></v-btn>
                </v-col>
                <v-col cols="4">
                    <v-card outlined id="transcript" max-height="1000px">
                        <v-card-title>Transcript</v-card-title>
                        <v-card-text>
                            <p v-for="(utterance, i) in utterances" :key="i" @click="goToTime(utterance.start)">
                                {{utterance.text}}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar v-model="snackbar" :timeout="2000">Link copied to clipboard</v-snackbar>
    </div>
    
    
</template>


<script>
// import {Auth} from 'aws-amplify';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'Stream',
    data() {
        return {
            videoId: null,
            cloudflareId: null,
            cloudflareUrl: "",
            utterances: this.utterances,
            videoPlayer: null,
            snackbar: false
        }
    },
    computed: {
        ...mapGetters({
        signedIn: 'isSignedIn',
        userIdJWTToken: 'getUserIdJWTToken',
        })
    },
    async created() {

        let videoId = this.$route.query.videoId;
        this.videoId = videoId;
        //cloudflare video stream api
        this.$loadScript("https://embed.videodelivery.net/embed/sdk.latest.js")
            .then(() => {
                this.videoPlayer = window.Stream(document.getElementById('videoPlayer'));
            })

        //go to the backend and get the video streaming info
        let headers = {"Authorization": this.userIdJWTToken};
        let res = await axios.get(`${process.env.VUE_APP_API_HOST}/stream?videoId=${videoId}`, {headers});
        this.utterances = res.data.utterances;
        this.cloudflareId = res.data.cloudflareId;
        this.cloudflareUrl = `https://iframe.videodelivery.net/${this.cloudflareId}`;
        this.topic = res.data.topic;
        this.hostEmail = res.data.hostEmail;
        this.createdAt = res.data.createdAt;

        if (this.$route.query.time) {
            this.videoPlayer.currentTime = parseFloat(this.$route.query.time);
        }
    },
    mounted() {
    },
    methods: {
        goToTime(time) {
            this.videoPlayer.currentTime = time / 1000;
        },
        copyToClipboard() {
            navigator.clipboard.writeText(`${window.location.origin}/#/stream?videoId=${this.videoId}&time=${this.videoPlayer.currentTime}`);
            this.snackbar = true;
        }
    }
}
</script>

<style lang="css">

#transcript {
  overflow: scroll
}
</style>