<template>
    <div>
        <v-app-bar fixed color="white">
            <v-btn icon @click="goHome">
                <v-icon>fas fa-chevron-left</v-icon>
            </v-btn>
            <v-app-bar-title>{{transcriptionName}}</v-app-bar-title>
            <v-spacer></v-spacer>
            <span v-if="utterances">{{saving ? "Saving..." : "Saved"}}</span>
            <v-spacer></v-spacer>
            <img src="/static/logo.png" height="40">
            <!-- <div class="app-bar-div">
                <div><v-icon>fas fa-chevron-left</v-icon></div>
                
                <img src="logo.png" height="40">
            </div> -->
        </v-app-bar>        
        <!-- <br> -->
        <div class="container page">
            <div contenteditable="true" ref="transcriptDiv" id="transcriptDiv" @keyup="divChanged" @keydown="onTyped" @click="transcriptClicked">
            </div>
        </div>
        <div v-for="(utterance, i) of utterances" :key=i hidden>
            <p v-for="(word, j) of utterance.words" :key=j ::style="[isWordActive(i, j) ? {'font-weight': 'bold'} : null]">

            </p>
        </div>

        <v-bottom-navigation
            fixed
            color="primary"
            horizontal
        >
        
        <audio 
            @timeupdate="timeUpdated"
            @canplay="onCanPlay"
            ref="audioPlayer"
            controls
        >
            <source src="" preload="auto">
        </audio>

            <v-menu offset-y v-model="playbackMenuOpened" closeOnContentClick>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="seeking = false"
                    >
                    {{playbackRate}} x
                    </v-btn>
                </template>
                <v-list dense>

                    <v-list-item 
                        v-for="(speed,i) in speeds" :key=i
                        @click="setPlaybackRate(speed)"
                    >
                        <v-list-item-title>{{speed}} x</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn
                @click="openSpeakersDialog"
            >
                <span>Speakers</span>
                <v-icon>fas fa-user-friends</v-icon>
            </v-btn>
            <v-dialog
                v-model="showSpeakersDialog"
                width="500"
                ref="speakerSelect"
                @click:outside="closeSpeakersDialog"
            >

                <v-card>
                    <v-card-title>
                        Manage Speakers
                    </v-card-title>
                    <v-card-text>
                        <v-combobox
                            v-model="speakers"
                        hide-selected
                        hint="Type the speaker's name, then press 'Tab'"
                        multiple
                        persistent-hint
                        small-chips
                        @change="speakersChanged"
                        ref="speakersList"
                        id="speakersList"
                        append-icon=""
                    ></v-combobox>

                    </v-card-text>
                </v-card>
            </v-dialog>
            
            <v-btn @click="openExportDialog">
                <span>Export</span>
                <v-icon>fas fa-file-export</v-icon>
            </v-btn>

            <v-dialog
                v-model="showExportDialog"
                @click:outside="closeExportDialog"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Export
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            :items="fileTypes"
                            item-text="description"
                            item-value="value"
                            label="File type"
                            v-model="fileType">
                        </v-select>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="exportFile" depressed color="#1673df" :disabled="exportProcessing" id="exportButton">
                                Export
                                <v-icon v-if="exportProcessing">fas fa-circle-notch fa-spin</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>

                    </v-card-text>
                </v-card>    
            </v-dialog>

            <v-btn @click="openInstructionsDialog">
                <span>Shortcuts</span>
                <v-icon>fas fa-keyboard</v-icon>
            </v-btn>
            <v-dialog 
                v-model="showInstructionsDialog"
                @click:outside="closeInstructionsDialog"
                width="500">
                <v-card>
                    <v-card-title>Transcript Editor - Shortcuts</v-card-title>
                    <v-card-text>
                        Welcome to shobot's online transcript editor. Here, you can proofread your transcript, fix mistakes, and export your content.
                        <br><br>
                        Shortcuts:
                        <br><br>
                        <table>
                            <tr>
                                <td><span class="shortcut">TAB</span></td>
                                <td style="text-align:right">Play/Pause</td>
                            </tr>
                            <br>
                            <tr>
                                <td><span class="shortcut">CTRL</span>+<span class="shortcut">Z</span></td>
                                <td style="text-align:right">Undo</td>
                            </tr>
                            <br>
                            <tr>
                                <td><span class="shortcut">CTRL</span>+<span class="shortcut">Y</span></td>
                                <td style="text-align:right">Redo</td>
                            </tr>
                        </table>
                        <br><br>
                        Need more help? <a href="https://www.shobot.io/contact-us" target="_blank">Contact Support</a>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
        </v-bottom-navigation>
    </div>
</template>


<script>
// import {Auth} from 'aws-amplify';
import axios from 'axios';
import { mapGetters } from 'vuex';
import FileDownload from 'js-file-download';

// import VuetifyAudio from 'vuetify-audio';
export default {
    name: 'Editor',
    components: {
        // VuetifyAudio,
    },
    data() {
        return {
            utterances: [],
            processing: false,
            childNum: 0,
            speeds: [0.25, 0.5, 0.75, 1.0, 1.25, 1.50, 1.75, 2.0],
            playbackRate: 1.0,
            showSpeakersDialog: false,
            showExportDialog: false,
            showInstructionsDialog: false,
            speakers: [],
            seeking: true,
            transcriptionId: null,
            renderingOriginalTranscript: false,
            currentTime: 0,
            audioReady: false,
            playbackMenuOpened: false,
            fileTypes: [
                {'description': 'Microsft Word (*.docx)', 'value': 'docx'},
                {'description': 'CSV (*.csv)', 'value': 'csv'},
                {'description': 'HTML (*.html)', 'value': 'html'}
                ],
            fileType: "docx",
            exportProcessing: false,
            typingTimer: 0,
            transcriptionName: "",
            saving: false
        }
    },
    computed: {
        ...mapGetters({
            signedIn: 'isSignedIn',
            userIdJWTToken: 'getUserIdJWTToken',
        }),
    },
    async created() {
        this.transcriptionId = this.$route.query.transcriptionId;
        let headers = {'Authorization': this.userIdJWTToken};
        let res = await axios.get(`${process.env.VUE_APP_API_HOST}/transcription/${this.transcriptionId}`, {headers});
        this.transcriptionName = res.data.transcriptionName;
        this.utterances = res.data.transcript.utterances;
        this.speakers = res.data.transcript.speakers || [];
        this.$refs.audioPlayer.src = res.data.audioUrl;
        this.renderTranscript2();
    },
    async mounted() {
        //tab event listener
        window.addEventListener("keydown", e => {
            if (e.keyCode === 9) {
                e.preventDefault();
                if (!this.audioReady || !this.$refs.audioPlayer) {
                    return;
                }
                if (this.$refs.audioPlayer.paused) {
                    this.$refs.audioPlayer.play();
                } else {
                    this.$refs.audioPlayer.pause();
                }
            }
        })
    },
    watch: {
        speakers: {
            handler(newSpeakers) {
                if (this.renderingOriginalTranscript) {
                    //don't want to overwrite the speakers before original transcript is finished rendering
                    return;
                }
                // let addedSpeakers = newSpeakers.filter(speaker => !oldSpeakers.includes(speaker));
                // let optionMap = {};
                // for (let speaker of newSpeakers) {
                //     optionMap[speaker] = `<option value="${speaker}">${speaker}</option>`
                // }
                //make options for new each speaker
                let html = `<option disabled selected value style="color:#6f747b">Speaker ▼</option>`
                for (let speaker of newSpeakers) {
                    html += `<option value="${speaker}">${speaker}</option>`
                }
                // console.log(html)
                document.getElementsByClassName('speakerSelect').forEach(select => {
                    let originalValue = select.value;
                    select.innerHTML = html;
                    if (originalValue) {
                        if (newSpeakers.includes(originalValue)) {
                            select.value = originalValue;
                        }
                    }
                })
            },
            deep: true
        },
        playbackMenuOpened: {
            handler(val) {
                // a hack - wait a sec before setting seeking to true so that the playback rates menu opens quickly
                if (!val) {
                    var that = this;
                    setTimeout(function() {
                        if (!val) that.seeking = true;
                    }, 100)
                }
            },
            deep: true
        }
    },
    methods: {
        onCanPlay() {
            console.log("audio ready")
            this.audioReady = true;
        },
        transcriptClicked(e) {
            // console.log(e.target)
            if (e.target.nodeName === "SPAN") {
                let wordId = e.target.classList[0]
                let i = parseInt(wordId.split(",")[0])
                let j = parseInt(wordId.split(",")[1])
                let timestamp = this.utterances[i].words[j].start;
                this.$refs.audioPlayer.currentTime = timestamp / 1000;
            }
        },
        setPlaybackRate(speed) {
            this.playbackRate = speed;
            this.$refs.audioPlayer.playbackRate = speed;
            // this.seeking = true;
        },
        renderTranscript2() {
            this.renderingOriginalTranscript = true;
            let html = "";
            for (let i = 0; i < this.utterances.length; i++) {
                let utterance = this.utterances[i];
                let p;
                let spans="";
                for (let j = 0; j < utterance.words.length; j++) {
                    let word = utterance.words[j];
                    let span = 
                    `<span class=${i},${j}>${word.text}</span>`;
                    spans += span;
                }
                let options = `<option disabled ${utterance.speaker ? "": "selected"} value style="color:#6f747b">Speaker ▼</option>`
                for (let speaker of this.speakers) {
                    options += `<option value="${speaker}" ${utterance.speaker === speaker ? "selected": ""}>${speaker}</option>`
                }
                p = 
                `
                    <div class="utteranceContainer" contenteditable="false" id=${i}>
                        <div class="utteranceInfo" contenteditable="false">
                            <select class="speakerSelect">
                                ${options}
                            </select>
                            <span class="timestamp" id="timestamp-${i}"></span>
                        </div>
                    </div>
                    <p class="utterance ${i}" contenteditable="true">${spans}</p>`
                html += p;
            }
            this.$refs["transcriptDiv"].innerHTML = html;

            document.getElementsByClassName("utterance").forEach(utterance => {
                let word = utterance.querySelectorAll('span')[0]
                let wordId = word.classList[0];
                let i = parseInt(wordId.split(",")[0])
                let j = parseInt(wordId.split(",")[1])
                let startTimestamp = this.utterances[i].words[j].start;
                document.getElementById(`timestamp-${i}`).innerHTML = this.convertToDuration(startTimestamp);
            });

            this.renderingOriginalTranscript = false;
        },
        convertToDuration(timestamp) {
            timestamp /= 1000.0;
            let hours = Math.floor(timestamp / 3600);
            timestamp %= 3600;

            let minutes = Math.floor(timestamp / 60);
            timestamp %= 60;

            let seconds = Math.floor(timestamp);
            let string = "";
            if (hours > 0)  {
                if (hours < 10) {
                    string += '0';
                }
                string += `${hours}:`
            }

            if (minutes < 10) {
                string += '0';
            }
            string += `${minutes}:`;

            if (seconds < 10) {
                string += '0';
            }
            string += `${seconds}`;

            return string;
        },
        divChanged() {
            if (document.getElementsByClassName('utterance').length > document.getElementsByClassName('utteranceContainer').length) {
                // let utterances = document.getElementById('transcriptDiv').querySelectorAll('p.utterance')
                let newUtterance = window.getSelection().anchorNode.parentElement.parentElement;
                let word = newUtterance.querySelectorAll('span')[0];
                let wordId;
                if (word.classList.length > 0) {
                    wordId = word.classList[0];
                } else {
                    let prevUtterance = newUtterance.previousElementSibling;
                    let prevWord = prevUtterance.lastChild;
                    console.log(prevWord);
                    wordId = prevWord.classList[0];
                    word.classList.add(wordId);
                }

                let i = parseInt(wordId.split(",")[0])
                let j = parseInt(wordId.split(",")[1])
                let startTimestamp = this.utterances[i].words[j].start;
                let timestamp = this.convertToDuration(startTimestamp);
                
                let options = "";
                for (let speaker of this.speakers) {
                    options += `<option value="${speaker}">${speaker}</option>`
                }
                let newDiv = document.createElement('div');
                newDiv.classList.add("utteranceContainer")
                // newDiv.class = 'utteranceContainer';
                newDiv.contenteditable = 'false';
                newDiv.innerHTML = `
                    <div contenteditable="false" class="utteranceInfo">
                        <select class="speakerSelect">
                            <option disabled selected value style="color:#6f747b">Speaker ▼</option>
                            ${options}
                        </select>
                        <span class="timestamp">${timestamp}</span>
                    </div>
                `

                newUtterance.parentNode.insertBefore(newDiv, newUtterance)
                // console.log(prevUtterance)
                // let classList = utterances.map(utterance => utterance.classList[1]);
                // console.log(classList)
            } else if (document.getElementsByClassName('utterance').length < document.getElementsByClassName('utteranceContainer').length) {
                console.log("got here")
                let currUtterance = window.getSelection().anchorNode.parentElement.parentElement;
                let divToBeDeleted = currUtterance.nextSibling;
                divToBeDeleted.remove()
                console.log(divToBeDeleted);
            }
        },
        onTyped() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                console.log("saving")
                this.save();
            }, 2000)
        },
        buttonClick() {
            console.log(this.$refs.audioPlayer.playbackRate = 1.5);
            // this.$refs.audioPlayer.pause();
        },
        async save() {
            this.saving = true;
            let modifiedUtterances = [];
            this.$refs.transcriptDiv.getElementsByClassName("utteranceContainer").forEach(utteranceContainer => {
                // let utteranceContainer = this.$refs.transcriptDiv.getElementsByClassName("utteranceContainer")[1];
                let modifiedUtterance = {};
                let speaker = utteranceContainer.querySelectorAll(".speakerSelect")[0].value;
                modifiedUtterance.speaker = speaker;
                modifiedUtterance.words = [];

                let utterance = utteranceContainer.nextSibling;
                if (utterance.nodeName !== "P") {
                    utterance = utterance.nextSibling;
                }
                let utteranceMap = {};
                utterance.querySelectorAll("span").forEach(word => {
                    if (word.classList.length === 0) {
                        //when paragraphs are joined, it's possible that the appended paragraph loses its classlist, so need to assign it classes here
                        //I think this only happens when there's a one-span paragraph. Just assign it to be the first word of the next utterance
                        let prevWord = word.previousElementSibling;
                        let i = parseInt(prevWord.classList[0].split(",")[0])
                        word.classList.add(`${i + 1},0`)
                    }
                    let classes = word.classList.toString();
                    if (!utteranceMap[classes]) {
                        utteranceMap[classes] = [];
                    }
                    utteranceMap[classes].push(word.innerHTML);
                })

                Object.keys(utteranceMap).forEach(key => {
                    let i = parseInt(key.split(",")[0])
                    let j = parseInt(key.split(",")[1])
                    let wordData = this.utterances[i].words[j];
                    
                    let text = utteranceMap[key].join("");
                    modifiedUtterance.words.push({
                        start: wordData.start,
                        end: wordData.end,
                        text: text
                    }); 
                })
                modifiedUtterances.push(modifiedUtterance);
            })
            
            let body = {
                utterances: modifiedUtterances,
                speakers: this.speakers
            }

            // console.log(transcriptMarkup)
            let headers = {'Authorization': this.userIdJWTToken};
            try {
                await axios.put(`${process.env.VUE_APP_API_HOST}/transcription/${this.transcriptionId}`, body, {headers})
            } catch(e) {
                console.log("failed to save", e)
            }
            this.saving = false;

        },
        timeUpdated() {
            //player time is in seconds, transcript time is in milliseconds
            this.currentTime = this.$refs.audioPlayer.currentTime * 1000;
            // console.log(this.currentTime);
        },
        isWordActive(i, j) {
            if (!this.seeking) {
                return false;
            }
            try {
                let word = this.utterances[i].words[j]
                let els = document.getElementsByClassName(`${i},${j}`);
                els.forEach(el => {
                    if (!el) {
                        return false;
                    }
                    if (word.start < this.currentTime) {
                        el.style.color = "black"
                    } else {
                        el.style.color = "#6f747b"
                    }
                })
            } catch (err) {
                console.log(`${i},${j}`);
            }
            return false;
        },
        openSpeakersDialog() {
            this.seeking = false;
            this.$refs.audioPlayer.pause();
            this.showSpeakersDialog = true;
        },
        closeSpeakersDialog() {
            //wait a sec before setting seeking to 'true' so that dialog closes quickly
            this.showSpeakersDialog = false;
            let that = this;
            setTimeout(function() {
                that.seeking = true;
            }, 100)
        },
        closeExportDialog() {
            //wait a sec before setting seeking to 'true' so that dialog closes quickly
            this.showExportDialog = false;
            let that = this;
            setTimeout(function() {
                that.seeking = true;
            }, 100)
        },
        openExportDialog() {
            this.seeking = false;
            this.$refs.audioPlayer.pause();
            this.showExportDialog = true;
        },
        openInstructionsDialog() {
            this.seeking = false;
            this.$refs.audioPlayer.pause();
            this.showInstructionsDialog = true;
        },
        closeInstructionsDialog() {
            //wait a sec before setting seeking to 'true' so that dialog closes quickly
            this.showInstructionsDialog = false;
            let that = this;
            setTimeout(function() {
                that.seeking = true;
            }, 100)
        },
        speakersChanged(e) {
            // console.log("speakers changed", e)
            this.speakers = e;
            // document.getElementById("speakers")
        },
        checkTime() {
            console.log(this.sound.seek() * 1000)
        },
        async exportFile() {
            this.exportProcessing = true;
            await this.save();

            let headers = {'Authorization': this.userIdJWTToken};
            try {
                let res = await axios.get(`${process.env.VUE_APP_API_HOST}/transcription/${this.transcriptionId}/${this.fileType}`, {headers, responseType:'blob'})
                FileDownload(res.data, `${this.transcriptionName}.${this.fileType}`)
            } catch(err) {
                console.log(`failed to get ${this.fileType} export`, err)
            }

            this.exportProcessing = false;
        },
        async goHome() {
            await this.save();
            this.$router.push("/")
        }
    }
}
</script>

<style lang="css">

#transcript {
  overflow: scroll
}

div.page {
    background: white;
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
    padding: 50px;
    margin-top: 100px;
    margin-bottom: 100px;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.utteranceContainer {
    user-select: none
}

div.utteranceInfo {
    font-size: 12px;
}

select.speakerSelect {
    font-style: italic;
}

select.speakerSelect:hover {
    cursor: pointer;
}

.v-list-item:hover {
    cursor: pointer;
    background-color: #f1f3f4;
}

.v-input__icon--append {
    display: none
}

audio::-webkit-media-controls-panel
 {
    background-color: white;
    border: 2px solid black
}

.v-bottom-navigation > button {
    border-left: 1px solid #e1e1e1
}

.v-bottom-navigation > button:last-of-type {
    border-right: 1px solid #e1e1e1
}


#exportButton {
    color:white;
}

.app-bar-div {
    height: 60px;
    width:100%;
    color: white;
    padding:10px;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.app-bar-div>div {
        /* height: 100%; */
        display: inline;
        /* text-align: center; */
        margin-top: 100px;
        /* margin-bottom:auto; */

    /* height:100% */
}

.shortcut {
    font-weight: bold;
    padding: 7px;
    border-radius: 4px;
        border: 1px solid #e7e7e7;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);

}
/* audio::-webkit-media-controls-panel */
/* 
.utterance {
    display: inline-block;
    margin-top: 10px;
} */
</style>